import React, { Component } from "react";
import "./App.scss";
import { Route, Router } from "react-router-dom";
import { createBrowserHistory, History } from "history";
import RootContainer from "packages/Root";

class App extends Component {
  browserHistory: History = createBrowserHistory();

  render() {
    return (
      <Router history={this.browserHistory}>
        <Route path="*" render={(props) => <RootContainer {...props} />} />
      </Router>
    );
  }
}

export default App;
