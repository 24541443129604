import React, { FunctionComponent } from "react";
import { ROUTES } from "common/constants";
import { NavLink } from "react-router-dom";
import "./NotFound.scss";

const NotFound: FunctionComponent = () => {
  console.log("not found");

  let flow;
  let navLink;

  if (window.location.pathname.includes(ROUTES.code)) {
    flow = "code directory";
    navLink = (
      <NavLink className="logo" exact activeClassName="active" to={ROUTES.code}>
        RE-ENTER CODE DIRECTORY
      </NavLink>
    );
  } else if (window.location.pathname.includes(ROUTES.film)) {
    flow = "film directory";
    navLink = (
      <NavLink className="logo" exact activeClassName="active" to={ROUTES.film}>
        RE-ENTER FILM DIRECTORY
      </NavLink>
    );
  } else {
    flow = "main directory";
    navLink = (
      <NavLink className="logo" exact activeClassName="active" to="/">
        RE-ENTER MAIN DIRECTORY
      </NavLink>
    );
  }

  return (
    <div className="wrapper">
      <div className="contentContaienr">
        <h1>Uh oh</h1>
        <p>Looks like your lost. Click below to re-enter the {flow} </p>
        {navLink}
      </div>
    </div>
  );
};

export default NotFound;
