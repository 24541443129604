export interface IEducationData {
  id: string;
  name: string;
  description: string;
  program: string;
  startDate: Date;
  endDate: Date;
}

export interface IPortfolioData {
  id: string;
  company: string;
  coverImage?: {
    alt: string;
    url: string;
    height: number;
    width: number;
  };
  summary?: string;
  description?: string;
  role: string;
  title: string;
  website?: string;
  github?: string;
  imdb?: string;
}

export interface IExperienceData {
  id: string;
  company: string;
  description: string;
  role: string;
  startDate: Date;
  endDate: Date;
  summary: string;
  skills: {
    tech: string[];
    soft: string[];
  };
}

export enum ScreenSize {
  Desktop,
  Mobile,
}

export const getFormatedDate = (startDate: Date, endDate?: Date) => {
  const start = startDate
    ? `${new Date(startDate).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
      })} -`
    : "";

  const end = endDate
    ? `${new Date(endDate).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
      })}`
    : "Current";

  return `${start} ${end}`;
};
