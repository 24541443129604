import React from "react";
import "./Slider.scss";

interface Props {
  sliderItems: JSX.Element[];
}

export default function Slider({ sliderItems }: Props) {
  return (
    <div className="slider">
      <div className="dot-container">
        {sliderItems.map((__, index: number) => {
          return (
            <a key={index} href={`#slide-${index}`}>
              <div className="dot">
                <span>{index + 1}</span>
              </div>
            </a>
          );
        })}
      </div>

      <div className="slides">
        {sliderItems.map((x: JSX.Element, index: number) => {
          return (
            <div key={index} id={`slide-${index}`}>
              {x}
            </div>
          );
        })}
      </div>
    </div>
  );
}
