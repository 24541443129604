import React, { FunctionComponent } from "react";
import "./PortfolioCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IPortfolioData } from "../../../types";

interface IProps {
  portfolio: IPortfolioData;
  flow: string;
}

const PortfolioCard: FunctionComponent<IProps> = ({ portfolio }) => {
  const arrowsLeft = faAngleDoubleLeft as IconProp;
  const arrowsRight = faAngleDoubleRight as IconProp;

  function getNumber() {
    let number;

    if (portfolio.coverImage) {
      number =
        (portfolio.coverImage!.height / portfolio.coverImage!.width) * 24.25;
    } else {
      number = 33;
    }

    return Math.round(number);
  }

  return (
    <div
      className="port-card"
      style={{
        gridRowEnd: `span ${getNumber()}`,
      }}
    >
      <img
        className="cover-image"
        src={
          portfolio.coverImage?.url ||
          "https://source.unsplash.com/random/?nature"
        }
        alt={portfolio.coverImage?.alt}
      />

      <div className="overlay">
        <div className="doodles">
          <div className="arrow-row">
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
          </div>
          <div className="arrow-row mobile">
            <FontAwesomeIcon icon={arrowsRight} />
            <FontAwesomeIcon icon={arrowsRight} />
            <FontAwesomeIcon icon={arrowsRight} />
            <FontAwesomeIcon icon={arrowsRight} />
          </div>
        </div>
        <div className="text">
          <div className="wrap">
            <span className="port-title">{portfolio.title}</span>
            <span className="port-company">{portfolio.company}</span>
            <div className="port-links"></div>
          </div>
          <p className="port-details">{portfolio.summary}</p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
