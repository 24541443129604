import React, { FunctionComponent } from "react";
import "./Contact.scss";
import ActionButton from "common/ActionButton";
import useWindowSize from "utils/useWindowSize";

const Contact: FunctionComponent = () => {
  const desktopMarkup = (
    <div className="contact-container">
      <div className="contact-form-container">
        <span className="contact-header">Let's work together.</span>
        <form
          className="contact-form"
          method="POST"
          action="https://formspree.io/f/xnqlgbea"
        >
          <input
            className="input"
            type="text"
            placeholder="name"
            name="name"
            required
          />
          <input
            className="input"
            type="email"
            placeholder="email"
            name="_replyto"
            required
          />
          <textarea
            className="input area"
            placeholder="message"
            name="message"
            required
          />

          <ActionButton
            color="black"
            type="submit"
            page="contact"
            label="SUBMIT"
          />
        </form>
      </div>

      <div className="map"></div>
    </div>
  );
  const mobileMarkup = (
    <div className="mobile-contact-container">
      {/* <div className="mobile-contact-form-container"> */}
      <span className="mobile-contact-header">Let's work together.</span>
      <form
        className="mobile-contact-form"
        method="POST"
        action="https://formspree.io/f/xnqlgbea"
      >
        <div className="text-inputs">
          <input
            className="input"
            type="text"
            placeholder="name"
            name="name"
            required
          />
          <input
            className="input"
            type="email"
            placeholder="email"
            name="_replyto"
            required
          />
          <textarea
            className="input area"
            placeholder="message"
            name="message"
            required
          />
        </div>

        <ActionButton
          color="black"
          type="submit"
          page="contact"
          label="SUBMIT"
          size="tiny"
        />
      </form>
      {/* </div> */}
    </div>
  );

  const renderedContent = useWindowSize(800) ? desktopMarkup : mobileMarkup;

  return <>{renderedContent}</>;
};

export default Contact;
