import React, { FunctionComponent } from "react";
import "./Home.scss";
import ActionButton from "../common/ActionButton";
import { NavLink } from "react-router-dom";
import { ROUTES } from "common/constants";
import { LaptopIcon, ProjectorIcon } from "assets/images/index";
import useWindowSize from "utils/useWindowSize";

const Home: FunctionComponent = () => {
  const desktopMarkup = (
    <div className="home-container">
      <div className="code-container common-container">
        <NavLink exact to={ROUTES.code}>
          <LaptopIcon className="flow-icon" />
          <ActionButton color="white" label="CODE" />
        </NavLink>
      </div>

      <div className="film-container common-container">
        <NavLink exact to={ROUTES.film}>
          <ProjectorIcon className="flow-icon" />
          <ActionButton color="black" label="FILM" />
        </NavLink>
      </div>
    </div>
  );

  const mobileMarkup = (
    <div className="home-container mobile">
      <div className="code-container common-container mobile">
        <NavLink exact to={ROUTES.code}>
          <LaptopIcon className="flow-icon mobile" />
          <ActionButton color="white" label="CODE" size="small" />
        </NavLink>
      </div>

      <div className="film-container common-container mobile">
        <NavLink exact to={ROUTES.film}>
          <ProjectorIcon className="flow-icon mobile" />
          <ActionButton color="black" label="FILM" size="small" />
        </NavLink>
      </div>
    </div>
  );

  const renderedContent = useWindowSize(550) ? desktopMarkup : mobileMarkup;

  return <>{renderedContent}</>;
};

export default Home;
