import React, { FunctionComponent, useState } from "react";
import "./Experience.scss";
import ExperienceCard from "common/Cards/Experience/ExperienceCard";
import ExperienceCardMobile from "common/Cards/Experience/ExperienceCardMobile";

import Slider from "../../../common/Slider/Slider";
import useWindowSize from "utils/useWindowSize";
import { IExperienceData, ScreenSize } from "types";
import { useQuery } from "graphql-hooks";

interface IProps {
  flow: string;
}

const Experience: FunctionComponent<IProps> = ({ flow }) => {
  const [activeExp, setIsActive] = useState<IExperienceData | null>();

  const screenSize = useWindowSize(1000)
    ? ScreenSize.Desktop
    : ScreenSize.Mobile;

  // flow without the backslash
  const rawFlow = flow.substring(1);

  const ExperienceQuery = `query getExperiences {
    allExperiences(filter: {
      flow: {
        eq: ${rawFlow}
      },
    }, orderBy: endDate_DESC) {
      id
      company
      description
      role
      startDate
      endDate
      skills
      summary
    }
  }`;

  const { loading, error, data } = useQuery(ExperienceQuery);

  if (loading) {
    return <p className="logo">LOADING</p>;
  }

  if (!data && error) {
    return <p className="logo">OH NO!</p>;
  }

  const expData: IExperienceData[] = data?.allExperiences;
  let techSkills: string[] = [];
  let softSkills: Array<string> = [];

  expData.forEach((exp: IExperienceData) => {
    exp.skills.tech.forEach((skill: string) => {
      techSkills.push(skill);
    });
  });

  expData.forEach((exp: IExperienceData) => {
    exp.skills.soft.forEach((skill: string) => {
      softSkills.push(skill);
    });
  });

  techSkills = Array.from(new Set(techSkills.flat(1))).sort();
  softSkills = Array.from(new Set(softSkills.flat(1))).sort();

  const desktopMarkup = (
    <div className="experience-container">
      {/* EXPERIENCE SECTION */}
      <div className="experience-section">
        {expData.map((exp: IExperienceData, index: number) => {
          return (
            <div
              key={index}
              onMouseEnter={() => {
                setIsActive(exp);
              }}
              onMouseLeave={() => {
                setIsActive(null);
              }}
            >
              <ExperienceCard exp={exp} activeExp={activeExp?.company} />
              {index !== expData.length - 1 && <hr className="divider" />}
            </div>
          );
        })}
      </div>

      {/* SKILLS SECTION */}
      <div className="skills-section">
        <div className="skills-cat">
          <span className="skills-title">Technical Skills</span>
          <div className="skills-box">
            {techSkills.map((skill: string, index: number) => {
              return (
                <div key={index}>
                  <span
                    className="skills-item"
                    style={
                      activeExp?.skills.tech.includes(skill)
                        ? { color: "yellow" }
                        : {}
                    }
                  >
                    {skill}
                  </span>
                  {index !== techSkills.length - 1 && (
                    <span style={{ color: "black" }}>• </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="skills-cat">
          <span className="skills-title">Soft Skills</span>
          <div className="skills-box">
            {softSkills.map((skill: string, index: number) => {
              return (
                <div key={index}>
                  <span
                    key={softSkills.indexOf(skill) + 100}
                    className="skills-item"
                    style={
                      activeExp?.skills.soft.includes(skill)
                        ? { color: "yellow" }
                        : {}
                    }
                  >
                    {skill}
                  </span>
                  {index !== softSkills.length - 1 && (
                    <span style={{ color: "black" }}>• </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );

  const mobileExpCards: JSX.Element[] = [];

  expData.forEach((exp: IExperienceData) => {
    mobileExpCards.push(
      <ExperienceCardMobile
        exp={exp}
        activeSkills={exp.skills.tech.concat(exp.skills.soft)}
      />
    );
  });

  const mobileMarkup = <Slider sliderItems={mobileExpCards} />;

  const renderedContent =
    screenSize === ScreenSize.Desktop ? desktopMarkup : mobileMarkup;

  return <>{renderedContent}</>;
};

export default Experience;
