import React, { FunctionComponent } from "react";
import Portfolio from "./Portfolio/Portfolio";
import Education from "./Education/Education";
import Experience from "./Experience/Experience";
import "./FlowContainer.scss";
import { NavLink } from "react-router-dom";
import useWindowSize from "utils/useWindowSize";

import { ROUTES } from "common/constants";

interface IProps {
  flow: string;
  page: string;
}

const FlowContainer: FunctionComponent<IProps> = ({ flow, page }) => {
  const active = () => {
    switch (page) {
      case ROUTES.experience:
        return <Experience flow={flow} />;
      case ROUTES.portfolio:
        return <Portfolio flow={flow} />;
      case ROUTES.education:
        return <Education flow={flow} />;
    }
  };

  return (
    <div className="flow-container">
      {useWindowSize(550) ? (
        <div className="flow-menu">
          <NavLink
            exact
            className="menu-item"
            activeClassName="active"
            to={flow + ROUTES.experience}
          >
            <div className="expRoute">
              <span>experience</span>
              {page === ROUTES.experience && (
                <hr style={{ color: "yellow" }}></hr>
              )}
            </div>
          </NavLink>

          <NavLink
            exact
            className="menu-item"
            activeClassName="active"
            to={flow + ROUTES.portfolio}
          >
            <div className="expRoute">
              <span>portfolio</span>
              {page === ROUTES.portfolio && (
                <hr style={{ color: "yellow" }}></hr>
              )}
            </div>
          </NavLink>

          <NavLink
            exact
            className="menu-item"
            activeClassName="active"
            to={flow + ROUTES.education}
          >
            <div className="expRoute">
              <span>education</span>
              {page === ROUTES.education && (
                <hr style={{ color: "yellow" }}></hr>
              )}
            </div>
          </NavLink>
        </div>
      ) : (
        <p className="mobile-header">{page.toUpperCase()}</p>
      )}
      {active()}
    </div>
  );
};

export default FlowContainer;
