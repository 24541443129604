import React, { FunctionComponent } from "react";
import "./ExperienceCard.scss";
import { getFormatedDate, IExperienceData } from "types";

interface IProps {
  exp: IExperienceData;
  activeExp?: string;
}

const ExperienceCard: FunctionComponent<IProps> = ({ exp, activeExp }) => {
  const hightlight =
    activeExp === exp.company
      ? {
          background: "yellow",
          marginLeft: "-3rem",
          width: "fit-content",
          paddingRight: "6rem",
        }
      : {};

  const alignment = activeExp === exp.company ? { marginLeft: "3rem" } : {};

  return (
    <div className="exp-card">
      <div style={hightlight}>
        <span style={alignment}>
          {exp.role} | {exp.company}
        </span>
        <p style={alignment}>{getFormatedDate(exp.startDate, exp.endDate)}</p>
      </div>
      <p style={{ whiteSpace: "pre-line" }}>{exp.summary}</p>
      <p style={{ whiteSpace: "pre-line" }}>{exp.description}</p>
    </div>
  );
};

export default ExperienceCard;
