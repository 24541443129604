import React, { FunctionComponent } from "react";

interface IProps {
  label: string;
  color: string;
  active?: boolean;
  type?: "button" | "reset" | "submit";
  size?: "tiny" | "small" | "medium" | "large";
  page?: string;
}

const ActionButton: FunctionComponent<IProps> = ({
  label,
  color,
  active = true,
  type,
  size = "medium",
  page,
}) => {
  const getFontSize = () => {
    switch (size) {
      case "tiny": {
        return { font: "20px", margin: "0.5rem 0px", padding: "1rem 3rem" };
      }
      case "small":
        return { font: "14px", margin: "0px auto 2rem auto", padding: "1rem" };
      default:
        return { font: "20px", margin: "0px auto", padding: "1rem 3rem" };
    }
  };

  return (
    <button
      style={{
        ...styles.action,
        border:
          page === "contact" ? `3px solid ${color}` : `1px solid ${color}`,
        color,
        opacity: active ? 1 : 0.3,
        cursor: "pointer",
        padding: getFontSize().padding,
        background: page === "contact" ? "#e5ed00" : "transparent",
        fontSize: page === "contact" ? "25px" : getFontSize().font,
        margin: page === "contact" && !size ? "2rem 0px" : getFontSize().margin,
        fontWeight: 700,
        letterSpacing: ".5rem",
        width: "100%",
      }}
      disabled={!active}
      type={type}
    >
      <span>{label}</span>
    </button>
  );
};

const styles: { [name: string]: React.CSSProperties } = {
  action: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
    fontFamily: "inherit",
  },
};

export default ActionButton;
