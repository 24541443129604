import React, { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../../common/constants";
import {
  BurgerIcon,
  ProjectorIcon,
  LaptopIcon,
} from "../../../../assets/images";
import "./HamburgerMenu.scss";

interface IProps {}

const Header: FunctionComponent<IProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div className="overlay">
      <div>
        <BurgerIcon onClick={toggleMenu}></BurgerIcon>
      </div>
      {isMenuOpen && (
        <div className="menu">
          <div className="openMenu">
            <p onClick={toggleMenu}>X</p>
          </div>

          <hr className="mobile-divider" />

          <div className="menuItems" onClick={toggleMenu}>
            <div className="code navItemsContainer">
              <LaptopIcon />
              <NavLink
                className="nav-item"
                exact
                activeClassName="active"
                to={`/code${ROUTES.experience}`}
              >
                <span style={{ color: "white" }}>EXPERIENCE</span>
              </NavLink>
              <NavLink
                className="nav-item"
                exact
                activeClassName="active"
                to={`/code${ROUTES.portfolio}`}
              >
                <span style={{ color: "white" }}>PORTFOLIO</span>
              </NavLink>
              <NavLink
                className="nav-item"
                exact
                activeClassName="active"
                to={`/code${ROUTES.education}`}
              >
                <span style={{ color: "white" }}>EDUCATION</span>
              </NavLink>
            </div>

            <hr className="mobile-divider" />

            <div className="film navItemsContainer">
              <ProjectorIcon />
              <NavLink
                className="nav-item"
                exact
                activeClassName="active"
                to={`/film${ROUTES.experience}`}
              >
                <span style={{ color: "white" }}>EXPERIENCE</span>
              </NavLink>
              <NavLink
                className="nav-item"
                exact
                activeClassName="active"
                to={`/film${ROUTES.portfolio}`}
              >
                <span style={{ color: "white" }}>PORTFOLIO</span>
              </NavLink>
              <NavLink
                className="nav-item"
                exact
                activeClassName="active"
                to={`/film${ROUTES.education}`}
              >
                <span style={{ color: "white" }}>EDUCATION</span>
              </NavLink>
            </div>

            <hr className="mobile-divider" />

            <NavLink
              className="nav-item"
              exact
              activeClassName="active"
              to={ROUTES.about}
            >
              <span style={{ color: "white" }}>ABOUT</span>
            </NavLink>
            {/* <NavLink
              className="nav-item"
              exact
              activeClassName="active"
              to={ROUTES.blog}
            >
              <span style={{ color: "white" }}>BLOG</span>
            </NavLink> */}
            <NavLink
              className="nav-item"
              exact
              activeClassName="active"
              to={ROUTES.contact}
            >
              <span style={{ color: "white" }}>CONTACT</span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
