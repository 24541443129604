import React from "react";
import "./ExperienceCardMobile.scss";
import { getFormatedDate, IExperienceData } from "types";

interface IProps {
  exp: IExperienceData;
  activeSkills: string[];
}

export default function ExperienceCardMobile({ exp, activeSkills }: IProps) {
  return (
    <div className="mobile-exp-container">
      <div>
        <p className="mobile-title role">{exp.role}</p>
        <p className="mobile-title">{exp.company}</p>

        <p className="mobile-date">
          {getFormatedDate(exp.startDate, exp.endDate)}
        </p>
      </div>
      <p className="mobile-content" style={{ whiteSpace: "pre-line" }}>
        {exp.summary}
      </p>
      <p className="mobile-content" style={{ whiteSpace: "pre-line" }}>
        {exp.description}
      </p>

      <div className="mobile-skills">
        {activeSkills.sort().map((skill: string, index: number) => {
          return (
            <span key={index}>
              <span className="skill">{skill}</span>
              {index !== activeSkills.length - 1 && (
                <span style={{ marginRight: ".25rem" }}>• </span>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
}
