import React, { FunctionComponent } from "react";
import "./Education.scss";
import EducationCard from "common/Cards/Education/EducationCard";
import useWindowSize from "utils/useWindowSize";
import EducationCardMobile from "common/Cards/Education/EducationCardMobile";
import { IEducationData, ScreenSize } from "types";
import { useQuery } from "graphql-hooks";

interface IProps {
  flow: string;
}

const Education: FunctionComponent<IProps> = ({ flow }) => {
  const screenSize = useWindowSize(1000)
    ? ScreenSize.Desktop
    : ScreenSize.Mobile;

  // flow without the backslash
  const rawFlow = flow.substring(1);

  const EducationQuery = `query getEducations {
    allEducations(filter: {flow: {eq: ${rawFlow}}}, orderBy: endDate_DESC) {
      id
      name
      description
      program
      startDate
      endDate
    
    }
  }`;

  const { loading, error, data } = useQuery(EducationQuery);

  if (loading) {
    return <p className="logo">LOADING</p>;
  }

  if (!data && error) {
    return <p className="logo">OH NO!</p>;
  }

  const eduData: IEducationData[] = data?.allEducations;

  const desktopMarkup = (
    <>
      <div className="education-container">
        {eduData.map((edu: IEducationData, index: number) => {
          return <EducationCard key={index} edu={edu} flow={flow} />;
        })}
      </div>
    </>
  );

  const mobileMarkup = eduData.map((edu: IEducationData, index: number) => {
    return <EducationCardMobile key={index} edu={edu} />;
  });

  const renderedContent =
    screenSize === ScreenSize.Desktop ? desktopMarkup : mobileMarkup;

  return <>{renderedContent}</>;
};

export default Education;
