import React, { FunctionComponent } from "react";
import "./Header.scss";
import { NavLink } from "react-router-dom";
import { ROUTES } from "common/constants";
import { LaptopIcon, ProjectorIcon } from "assets/images/index";
import useWindowSize from "utils/useWindowSize";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";

interface IProps {}

const Header: FunctionComponent<IProps> = () => {
  let icon;
  let switchRoute;

  if (window.location.pathname.includes(ROUTES.code)) {
    icon = "code";
    switchRoute = window.location.pathname.replace(/code/i, "film");
  } else if (window.location.pathname.includes(ROUTES.film)) {
    icon = "film";
    switchRoute = window.location.pathname.replace(/film/i, "code");
  } else {
    icon = null;
  }

  const desktopMarkup = (
    <div className="header-container">
      <NavLink className="logo" exact activeClassName="active" to="/">
        SYDNEY ALLISON
      </NavLink>

      {icon && switchRoute && (
        <div className="flow-icon">
          <NavLink exact to={switchRoute}>
            {icon === "film" ? <ProjectorIcon /> : <LaptopIcon />}
          </NavLink>
        </div>
      )}

      <div className="nav-container desktop">
        <NavLink
          className="nav-item"
          exact
          activeClassName="active"
          to={ROUTES.about}
        >
          about
        </NavLink>
        {/* <NavLink
          className="nav-item"
          exact
          activeClassName="active"
          to={ROUTES.blog}
        >
          blog
        </NavLink> */}
        <NavLink
          className="nav-item"
          exact
          activeClassName="active"
          to={ROUTES.contact}
        >
          contact
        </NavLink>
      </div>
    </div>
  );

  const mobileMarkup = (
    <div className="header-container mobile">
      <div className="column">
        {icon && switchRoute && (
          <NavLink exact to={switchRoute}>
            {icon === "film" ? <ProjectorIcon /> : <LaptopIcon />}
          </NavLink>
        )}
      </div>

      <div className="column">
        <NavLink className="logo mobile" exact activeClassName="active" to="/">
          SYDNEY ALLISON
        </NavLink>
      </div>

      <div className="column icon">
        <HamburgerMenu />
      </div>
    </div>
  );

  const renderedContent = useWindowSize(550) ? desktopMarkup : mobileMarkup;

  return <>{renderedContent}</>;
};

export default Header;
