import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./About.scss";
import useWindowSize from "utils/useWindowSize";
import { useQuery } from "graphql-hooks";
import { ScreenSize } from "types";

const About: FunctionComponent = () => {
  const arrowsLeft = faAngleDoubleLeft as IconProp;
  const screenSize = useWindowSize(1300)
    ? ScreenSize.Desktop
    : ScreenSize.Mobile;

  const BioQuery = `query getBio {
    bio {
      id
     body
      image {
        alt
        url
      }
      header
      
    }
  }`;

  const { loading, error, data } = useQuery(BioQuery);

  if (loading) {
    return <p className="logo">LOADING</p>;
  }

  if (!data && error) {
    return <p className="logo">OH NO!</p>;
  }

  const { image, header, body } = data.bio;

  const getAccentWords = (text: string) => {
    let stringArray = text.split(" ");
    const first = stringArray.shift();
    const last = stringArray.pop();

    return (
      <>
        <span>{first} </span>
        {stringArray.join(" ")} <span>{last}</span>
      </>
    );
  };

  const desktopMarkup = (
    <div className="about-container">
      <div className="graphics-container">
        <div className="left-graphics">
          <img className="desktop-img" src={image.url} alt={image.alt} />
          <div className="stripes-container">
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
        </div>
        <div className="arrows-container">
          <div className="arrow-row">
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
          </div>

          <div className="arrow-row">
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
          </div>

          <div className="arrow-row">
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
          </div>

          <div className="arrow-row">
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
            <FontAwesomeIcon icon={arrowsLeft} />
          </div>
        </div>
      </div>

      <div className="info-container">
        <div className="info-header">{getAccentWords(header)}</div>
        <p className="info-body" style={{ whiteSpace: "pre-line" }}>
          {body}
        </p>
      </div>
    </div>
  );

  const mobileMarkup = (
    <div className="about-container mobile">
      <div className="mobile-img-container">
        <img className="mobile-img" src={image.url} alt={image.alt} />
        <div className="mobile-info-header">{getAccentWords(header)}</div>
      </div>
      <p className="mobile-info" style={{ whiteSpace: "pre-line" }}>
        {body}
      </p>
    </div>
  );

  const renderedContent =
    screenSize === ScreenSize.Desktop ? desktopMarkup : mobileMarkup;

  return <>{renderedContent}</>;
};

export default About;
