import React from "react";
import { getFormatedDate, IEducationData } from "types";
import "./EducationCardMobile.scss";

interface IProps {
  edu: IEducationData;
}

export default function EducationCardMobile({ edu }: IProps) {
  return (
    <div className="mobile-exp-container">
      <div>
        <p className="mobile-title role">{edu.name}</p>
        <p className="mobile-title">{edu.program}</p>
        <p className="mobile-date">
          {getFormatedDate(edu.startDate, edu.endDate)}
        </p>
      </div>
      <p className="mobile-content" style={{ whiteSpace: "pre-line" }}>
        {edu.description}
      </p>
    </div>
  );
}
