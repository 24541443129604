import React, { FunctionComponent } from "react";
import PortfolioCard from "common/Cards/Portfolio/PortfolioCard";
import "./Portfolio.scss";
import { IPortfolioData } from "types";
import { useQuery } from "graphql-hooks";
interface IProps {
  flow: string;
}

const Portfolio: FunctionComponent<IProps> = ({ flow }) => {
  // flow without the backslash
  const rawFlow = flow.substring(1);

  const PortfolioQuery = `query getPortfolio {
      allPortfolios(filter: {flow: {eq: ${rawFlow}}}, orderBy: title_ASC) {
        id
       company
        coverImage {
          alt
          url
          height
          width
        }
        summary
        role
        title
      }
    }`;

  const { loading, error, data } = useQuery(PortfolioQuery);

  if (loading) {
    return <p className="logo">LOADING</p>;
  }

  if (!data && error) {
    return <p className="logo">OH NO!</p>;
  }

  const portData: IPortfolioData[] = data.allPortfolios;

  return (
    <div className="pinterest-container">
      {portData.map((port: IPortfolioData, index: number) => {
        return <PortfolioCard key={index} portfolio={port} flow={flow} />;
      })}
    </div>
  );
};

export default Portfolio;
