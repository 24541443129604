import React, { FunctionComponent } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROUTES } from "common/constants";
// Components
import About from "packages/Meta/About/About";
// import Blog from "packages/Meta/Blog/Blog";
import Contact from "packages/Meta/Contact/Contact";
import NotFound from "packages/Meta/NotFound/NotFound";
import Home from "../packages/Home";
import FlowContainer from "../packages/Flow/FlowContainer";

const Routes: FunctionComponent = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact render={() => <Home />} />
        <Route path={ROUTES.about} exact render={() => <About />} />
        {/* <Route path={ROUTES.blog} render={(props) => <Blog />} /> */}
        <Route path={ROUTES.contact} exact render={() => <Contact />} />

        {/* TODO refactor flow routing to reduce redundancies */}
        <Route
          path={ROUTES.code}
          render={({ match: { url } }) => (
            <Switch>
              <Route exact path={`${url}`}>
                <Redirect to={`${url}${ROUTES.experience}`} />
              </Route>

              <Route
                path={`${url}${ROUTES.experience}`}
                render={() => (
                  <FlowContainer flow={url} page={ROUTES.experience} />
                )}
                exact
              />
              <Route
                path={`${url}${ROUTES.portfolio}`}
                render={() => (
                  <FlowContainer flow={url} page={ROUTES.portfolio} />
                )}
                exact
              />
              <Route
                path={`${url}${ROUTES.education}`}
                render={() => (
                  <FlowContainer flow={url} page={ROUTES.education} />
                )}
                exact
              />

              <Route
                render={() => (
                  <>
                    <Redirect to={`${url}${ROUTES.error}`} />
                    <NotFound />
                  </>
                )}
              ></Route>
            </Switch>
          )}
        />

        <Route
          path={ROUTES.film}
          render={({ match: { url } }) => (
            <Switch>
              <Route exact path={`${url}`}>
                <Redirect to={`${url}${ROUTES.experience}`} />
              </Route>

              <Route
                path={`${url}${ROUTES.experience}`}
                render={() => (
                  <FlowContainer flow={url} page={ROUTES.experience} />
                )}
                exact
              />
              <Route
                path={`${url}${ROUTES.portfolio}`}
                render={() => (
                  <FlowContainer flow={url} page={ROUTES.portfolio} />
                )}
                exact
              />
              <Route
                path={`${url}${ROUTES.education}`}
                render={() => (
                  <FlowContainer flow={url} page={ROUTES.education} />
                )}
                exact
              />

              <Route
                render={() => (
                  <>
                    <Redirect to={`${url}${ROUTES.error}`} />
                    <NotFound />
                  </>
                )}
              ></Route>
            </Switch>
          )}
        />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
