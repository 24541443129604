import React, { Component } from "react";
import Header from "packages/Meta/Header/Header";
// import ScreenSizeCheck from "common/components/ScreenSizeCheck";
// import Cookie from "packages/Meta/containers/Cookies";

import Routes from "packages/Routes";

interface IProps {
  location: { hash: string };
}

class RouteContainer extends Component<IProps> {
  render() {
    return (
      <>
        <div style={styles.contentContainer}>
          <React.Fragment>
            <Header />
            <Routes />
            {/* {!this.props.cookiesAccepted && <Cookie />} */}
          </React.Fragment>
        </div>
      </>
    );
  }
}

const styles: { [name: string]: React.CSSProperties } = {
  contentContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
};

export default RouteContainer;
