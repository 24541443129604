import React, { FunctionComponent } from "react";
import "./EducationCard.scss";
import { LaptopIcon, ProjectorIcon } from "assets/images/index";
import { ROUTES } from "common/constants";
import { getFormatedDate, IEducationData } from "types";

interface IProps {
  edu: IEducationData;
  flow: string;
}

const EducationCard: FunctionComponent<IProps> = ({ edu, flow }) => {
  let icon;

  if (flow === ROUTES.code) {
    icon = <LaptopIcon />;
  } else {
    icon = <ProjectorIcon />;
  }

  return (
    <div className="education-card">
      {icon}
      <span className="education-title">{edu.name}</span>
      <span className="education-title">{edu.program}</span>
      <span>{getFormatedDate(edu.startDate, edu.endDate)}</span>
      <span style={{ whiteSpace: "pre-line" }}>{edu.description}</span>
    </div>
  );
};

export default EducationCard;
