export const ROUTES = {
    home: "/",
    about: "/about",
    women: "#women-in-tech",
    contact: "/contact",
    blog: "/blog",
    film: "/film",
    code: "/code",
    portfolio: "/portfolio",
    experience: "/experience",
    education: "/education",
    error: "/error"
  };